import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description;
  const metaUrl = site.siteMetadata.siteUrl;
  const defaultTitle = site.siteMetadata?.title;

  const staticMetadata = [
    {
      name: `description`,
      content: metaDescription,
    }, {
      property: `og:url`,
      content: metaUrl,
    },
    {
      property: `og:type`,
      content: `website`,
    }, {
      property: `og:title`,
      content: title,
    }, {
      property: `og:description`,
      content: metaDescription,
    }, {
      property: `og:image`,
      content: image,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    }, {
      name: `twitter:domain`,
      content: metaUrl,
    }, {
      name: `twitter:title`,
      content: title,
    }, {
      name: `twitter:description`,
      content: metaDescription,
    }, {
      name: `twitter:image`,
      content: image,
    },
  ].concat(meta)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={staticMetadata}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [
    {
      property: `og:type`,
      content: `website`,
    },
  ],
  description: ``,
  title: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo

